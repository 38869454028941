import { Typography,Box,Avatar,Card,CardContent,styled, Button, Menu, MenuItem, useTheme} from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useContext, useEffect, useRef, useState } from 'react';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { AuthContext } from 'src/contexts/FirebaseAuthContext';
import useAgentStore from 'src/hooks/UseStore';
import DownloadReport from './DownloadReport';
  
 const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        background: ${theme.colors.gradients.orange1};
        color:  ${theme.palette.primary.contrastText};
  `
);
  
function BankFees() {
  const theme = useTheme();
  const {user} = useContext(AuthContext)
  const agentType = useAgentStore((state) => state.agentType);
  const [data, setData] = useState(0);
  const [openPeriod, setOpenMenuPeriod] = useState(false);
  const [period, setPeriod] = useState('today');

  let id_usuario;
  if(agentType === 'Agente'){
    id_usuario = user?.id_agente;
  } else if(agentType === 'SubConcesionario'){
    id_usuario = user?.id_subconcesionario;
  } else if(agentType === 'Concesionario'){
    id_usuario = user?.id_concesionario;
  }

  useEffect(() => {
    fetch(`/api/homecomissions?id_autor=${id_usuario}&period=${period}`)
      .then((response) => response.json())
      .then((data) => {
        const sum = data.reduce((acc, curr) => acc + Number(curr.valor_comision), 0);
        const roundedSum = Math.round(sum * 100) / 100;
        const formattedSum = roundedSum.toFixed(2);
        setData(data.length ? formattedSum : '0.00');
      })
      .catch((error) => {
        console.error("Error fetching commissions:", error);
      });
  }, [period]);

    const periods = [
      {
        value: 'today',
        text: 'Hoy'
      },
      {
        value: '7_days',
        text: 'Ultimos 7 dias'
      },
      {
        value: '30_days',
        text: 'Ultimos 30 dias'
      },
      {
        value: '90_days',
        text: 'Ultimos 90 dias'
      }
    ];

    const columns = [
      { header: "ID Transacción", key: "id_transaccion", width: 25 },
      { header: "ID Comisión", key: "id_comision", width: 25 },
      { header: "Fecha", key: "fecha", width: 20 },
      { header: "Valor Comisión", key: "valor_comision", width: 15 },
      { header: "Valor Transacción", key: "valor_transaccion", width: 15 },
      { header: "ID Autor", key: "id_autor", width: 15 },
    ];

    const actionRef1 = useRef(null);

  
    return (
      <Card sx={{
        background: `${theme.colors.gradients.black1}`,
      }}>
        <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">     
          <Box display="flex" alignItems="center">
            <AvatarWrapper>
            <CurrencyExchangeIcon sx={{ color: 'lightgreen' }} />
            </AvatarWrapper>
            <Typography
              sx={{ ml: 1 }}
              variant="body1"
              color="white"
              component="div"
            >
              Comisiones
            </Typography>
          </Box>
          <Box>
    <Button
      size="small"
      variant="outlined"
      ref={actionRef1}
      onClick={() => setOpenMenuPeriod(true)}
      endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
    >
      {periods.find(p => p.value === period)?.text}
    </Button>
    <Menu
      disableScrollLock
      anchorEl={actionRef1.current}
      onClose={() => setOpenMenuPeriod(false)}
      open={openPeriod}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {periods.map((_period) => (
        <MenuItem
          key={_period.value}
          onClick={() => {
            setPeriod(_period.value);
            setOpenMenuPeriod(false);
          }}
        >
          {_period.text}
        </MenuItem>
      ))}
    </Menu>
  </Box>
        </Box>
        <Box
            display="flex"
            alignItems="center"
            sx={{
              ml: -1.5,
              py: 2,
              justifyContent: 'center'
            }}
          >
            <Typography variant="h2" color="white">
              $ {data}
            </Typography>
        </Box>
        <DownloadReport
          id={id_usuario}
          period={period}
          paramKey={"id_autor"}
          apiEndpoint="/api/homecomissions"
          worksheetName="Comisiones"
          fileName="comisiones"
          columns={columns}
        />
        </CardContent>
      </Card>
    );
  }
  
export default BankFees;
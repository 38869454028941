import {Box,Card,Typography,Avatar,styled,useTheme,CardContent,} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/contexts/FirebaseAuthContext";
import useAgentStore from "src/hooks/UseStore";

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.gradients.orange1};
        color:  ${theme.palette.primary.contrastText};
  `
);

function Affiliates() {
  const theme = useTheme();
  const [affiliates, setAffiliates] = useState(0);
  const {user} = useContext(AuthContext);
  const agentType = useAgentStore((state) => state.agentType);
  let id_usuario;

  if(agentType === 'Agente'){
    id_usuario = user?.id_agente;
  } else if(agentType === 'SubConcesionario'){
    id_usuario = user?.id_subconcesionario;
  } else if(agentType === 'Concesionario'){
    id_usuario = user?.id_concesionario;
  }

  useEffect(() => {
    fetch(`/api/affiliates?id_usuario=${id_usuario}`)
      .then((response) => response.json())
      .then((data) => {
        setAffiliates(data.affiliatesCount);
      })
      .catch((error) => {
        console.error("Error fetching affiliates count:", error);
      });
  }, []);

  return (
    <Card
      sx={{
        background: `${theme.colors.gradients.black1}`,
      }}
    >
      <CardContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <AvatarWrapper>
          <PersonAddIcon sx={{ color: 'lightgreen' }} />
          </AvatarWrapper>
          <Typography
            sx={{ ml: 1 }}
            variant="body1"
            color="white"
            component="div"
          >
            Afiliados
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              pr: 0.5,
              color: `${theme.colors.success.main}`,
              fontSize: '10px',  // Set font size to 10px
            }}
          >
            <ArrowUpwardTwoToneIcon
              fontSize="small"
              sx={{ mr: 0.2 }}
            />
            <span>0%</span>
          </Typography>
          <Typography
            noWrap
            sx={{
              color: `${theme.colors.alpha.trueWhite[70]}`,
              fontSize: '10px',  // Set font size to 10px
            }}
          >
            incremento este mes
          </Typography>
        </Box>
      </Box>

        <Box
          mt={1.5}
          pb={2}
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: 'center'
          }}
        >
 
            <Typography
              variant="h2"
              sx={{
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              {affiliates}
            </Typography>
 
        </Box>
      </CardContent>
    </Card>
  );
}

export default Affiliates;

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownloadTwoTone";
import { Button } from "@mui/material";

const DownloadReport = ({ id, period, paramKey,apiEndpoint, worksheetName, fileName, columns }) => {
  const downloadExcel = async () => {
    try {
      const response = await fetch(
        `${apiEndpoint}?${paramKey}=${id}&period=${period}`
      );
      const data = await response.json();

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(worksheetName);

      worksheet.columns = columns;

      data.forEach((item) => {
        worksheet.addRow(item);
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, `${fileName}_${period}.xlsx`);
    } catch (error) {
      console.error(`Error fetching and downloading ${worksheetName.toLowerCase()}:`, error);
    }
  };

  return (
    <Button
      size="small"
      color="secondary"
      variant="outlined"
      startIcon={<CloudDownloadTwoToneIcon />}
      onClick={downloadExcel}
    >
      Descargar Reporte
    </Button>
  );
};

export default DownloadReport;
